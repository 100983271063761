import React from "react";
import Typography from "@mui/material/Typography";

function Step1Header() {
  return (
    <>
      <Typography paragraph={true}>
        Filling in the form above will instantly give you a list of estimates
        and contact details from Domestic Energy Assessors who cover your area.
      </Typography>
      <Typography paragraph={true}>
        To calculate the price for your survey, your property details are only
        provided to relevant surveyors who cover your area, along with your
        email and phone number. If you do not want to give your phone number
        please put 0000 in this field.
      </Typography>
      <Typography paragraph={true}>
        Local Surveyors Direct will NOT use your contact details again, other
        than a brief feedback email to check that you were happy with the
        service provided. Please read <a href="https://www.localsurveyorsdirect.co.uk/customer-privacy-policy" target ="_blank" rel="noreferrer">LSD&apos;s Privacy Policy</a> for more information.
      </Typography>
      <Typography paragraph={true}>
        The HomeOwners Alliance will NOT use your contact details unless you
        have opted in to receive our free newsletter. Please read <a href="https://hoa.org.uk/our-site/home-owners-alliance-privacy-policy/" target="_blank" rel="noreferrer">HOA&apos;s Privacy
        Policy</a> for more information.
      </Typography>
    </>
  );
}

export default Step1Header;
